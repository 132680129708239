import React from "react"
import { Container, Row, Col } from "reactstrap"
import Image from "../components/image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutUs = () => {
  return (
    <Layout>
      <SEO title="About Us" description="" />
      <Container className="mt-50 pt-5">
        <Row>
          <Col md={7} className="d-flex flex-column justify-content-center">
            <h3 className="text-primary">About Us</h3>
            <div className="w-25 py-1 bg-primary mb-3 border-0" />
            <p className="text-justify">
              Your Repair Experts is your #1 Source for finding the correct
              nearby contractual workers to complete your home improvement,
              maintenance and repair projects. There is no compelling reason to
              invest hours calling several contract workers from the nearby
              telephone directory or scanning for organizations on the web. Our
              process saves you time and money. To begin, click here and pick
              the service you need to get quotes from local contractors. You'll
              be happy you did!
            </p>
          </Col>
          <Col md={5}>
            <Image src="contact-us.png" />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default AboutUs
